@import "../../styles/colors";

.CardProject{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px;
    width: calc(100% - 48px);
    height: 300px;
    background: rgba(70, 56, 159, 0.1);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    //animation: slide-in-bottom 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    &_information{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 222px;

        &_text{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 222px;
        }
        &_techno{
            display: flex;
            justify-content: flex-end;
            padding-right: 16px;
            min-width: 100px;
        }
    }

    &_image{
        object-fit: cover;
        width: 444px;
        height: 222px;
    }

    &:hover{
        background: rgba(70, 56, 159, 0.15);
        box-shadow: 0px 0px 10px 10px rgba(16, 15, 16, 0.534);
    }
}


@media screen and (max-width: 1050px) {
    .CardProject{
        flex-direction: column-reverse;
        padding: 24px 24px 24px 24px;
        width: calc(100% - 48px);
        height:calc(100% - 48px);

        &_information{
            height: 100%;
            flex-direction: column;
            gap: 2vh;
            &_text{
                height: 100%;
            }
            &_techno{
                display: flex;
                justify-content: flex-start;
                padding-right: 0px;
                min-width: 100px;
            }
        }

        &_image{
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
}


@keyframes slide-in-bottom {
    0%{
        transform: translateY(10px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@for $i from 1 through 7{
    #CardProject_#{$i}{
        animation-delay: 0.1s*$i;
    }
}

