@import "../../../styles/colors";



.menu-icon {
    margin: 20px auto;
    width: 20px;
    height: 14px;
    cursor: pointer;
    position: relative;
}

.menu-icon span {
    background-color: $color-white;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.menu-icon span:first-child {
    top: 0;
}

.menu-icon span:nth-child(2) {
    top: 6px;
}

.menu-icon span:last-child {
    top: 12px;
}

.menu-icon_active span:nth-child(2) {
    opacity: 0;
}

.menu-icon_active span:first-child,
.menu-icon_active span:last-child {
    top: 6px;
    background-color: $color-white;
    border-radius: 5px;
}

.menu-icon_active span:first-child {
    transform: rotate(45deg);
}

.menu-icon_active span:last-child {
    transform: rotate(-45deg);
}

.button_menu{
    background-color: $color-circle;
    padding: 0px 16px 0px 16px;
    border-radius: 100%;
    border: none;
    position: fixed;
    right: 5vw;
    top: 90vh;
    z-index: 5;
}

.mobile_menu{
    display: flex;
    opacity: 0;
    position: absolute;
    padding-top: 10vh;
    padding-left: 24px;
    height: 0;
    width: calc(100% - 24px);
    right: 0vh;
    top: 100vh;
    background-color: $color-black;
    transition: all 0.6s ;

    &_active{
        height: 100vh;
        opacity: 1;
        top: 0vh;
    }
}


@media screen and (min-width: 550px) {
    .button_menu{
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .mobile_menu{
        &_nav{
            height: 100%;
            width: 100%;
            &_liste{
                height: calc(100%  - 150px);
                width: calc(100%  - 24px);
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;
            }
        }
        & li{
            font-size: 42px;
        }

        .menu-icon_active{
            width: 100vw;
            height: 100vh;
        }
    }
}