.Profil {
    display: flex;
    padding: 0px 64px 0px 64px;
    width: calc(100% - 128px);
    height: 94vh;
    max-width: 1440px;

    &_left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        animation: profilFadeIn 0.3s ease-in-out;
    }

    &_right {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 32px;
        width: 50%;
        height: 100%;
        animation: profilFadeIn 0.3s ease-in-out;

        &_textes{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            text-align: left;
        }
        }
}

@media screen and (max-width: 1050px) {
    .Profil {
        padding: 0px 32px 0px 32px;
        width: calc(100% - 64px);
    }
}

@media screen and (max-width: 950px) {
    .Profil {
        padding: 100px 32px 0px 32px;
        width: calc(100% - 64px);
        flex-direction: column;
        align-items: center;
        gap: 0vh;
        height: 83vh;

        &_left {
            width: 100%;
        }

        &_right {
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            gap: 32px;
            text-align: center;
        }
    }
}

@media screen and (max-width: 550px) {
    .Profil {
        padding: 100px 32px 10vh 32px;
        width: calc(100% - 64px);
        height: 100%;
        &_left {
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_right {
            justify-content: center;
            width: 100%;
        }
    }
}

@keyframes profilFadeIn{
    0%{
        opacity: 0;
        transform: translate(0px, 10%);

    }

    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}