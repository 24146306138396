@import "../../styles/colors";

.Contact{
    padding: 160px 64px 160px 64px;
    width: calc(100% - 128px);
    display: flex;
    flex-direction: column;
    gap: 96px;

    &_text{ 
        display: flex;
        gap: 4px;
        padding: 32px 24px 32px 24px;
        width: calc(100% - 48px);
        background: rgba(70, 56, 159, 0.1);
        &_mail{
            color: $main-color;
        }
    }
}

@media screen and (max-width: 1050px) {
    .Contact{
        padding: 250px 64px 160px 64px;
    }
}


@media screen and (max-width: 686px) {
    .Contact{
        padding: 0px 32px 64px 32px;
        width: calc(100% - 64px);
        gap: 32px;
        &_text{
            flex-direction: column;
        }
    }
}
