.arrow{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    &_img{
        animation: bonced 4s infinite;
    }
}

@keyframes bonced{
    0%{
        transform: translate(0px, 0px);

    }

    50%{
        transform: translate(0px, 10px);

    }
    100%{
        transform: translate(0px, 0px);

    }
}

@media screen and (max-width: 550px) {
    .arrow {
        display: none;
    }
}