@import "../../styles/colors";

.CustomBouton{
    background-color: transparent;
    border: 2px solid $color-white;
    padding: 8px 16px 8px 16px;
    border-radius: 50px;
    transition: all 0.20s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: $color-white;
        color: $color-black;
    }
}