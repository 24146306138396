@import "styles/colors";

html{
    scroll-behavior: smooth;
}


body{
    position: relative;
    background-color: $color-black;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 650px) {
    body{
        gap: 10vh;
    }
}