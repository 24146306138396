@import "../../styles/colors";
@import "../../styles/font";

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 64px;
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(25px);
    position: fixed;
    left: 0px;
    top: 0px;
    width: calc(100% - 128px);
    z-index: 20;
    animation: navBarFadeIn 0.3s ease-in-out;
    animation-delay: 0.9;
}

.header_menu{
    & ul{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        
        & li{
           position: relative; 
           cursor: pointer;
        }
    }
}

.underline{
    position: absolute;
    width: 0px;
    height: 3px;
    left: 0px;
    bottom: -5px;
    transition: all 0.25s ease-in-out;
}

.header_p:hover >.underline{
    width: 80%;
    background-color: white;
}

@media screen and (max-width: 550px) {
    header{
        padding: 24px 32px;
        width: calc(100% - 64px);
    }
    
    
    .header_menu{
        display: none;
    }
}


@keyframes navBarFadeIn{
    0%{
        opacity: 0;
        transform: translate(0px, -10%);

    }

    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}