.Projets {
    padding: 0px 64px 0px 64px;
    width: calc(100% - 128px);
    display: flex;
    flex-direction: column;
    gap: 96px;
    &_cardsList {
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
}

@media screen and (max-width: 1050px) {
    .Projets {
        width: calc(100% - 128px);
        &_cardsList {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            gap: 10%;
        }
    }
}

@media screen and (max-width: 650px) {
    .Projets {
        padding: 0px 32px 100px 32px;
        width: calc(100% - 64px);
        gap: 5vh;
        &_cardsList {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            gap: 3vh;
        }
    }
}
