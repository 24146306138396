@import "../../styles/colors";

.bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: -100;
    max-width: 100vw;
    overflow: hidden;

    &_dot_list{
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.dot{
    position: absolute;
    top: 10%;
    border-radius: 100%;
    width: 10vw;
    height: 10vw;
    filter: blur(100px);
    background-color: $color-circle;
    animation: move_right 15s infinite;
}

#dot_1{
    top: 20px;
    right: -1vw;
    width: 20vw;
    height: 20vw;
    animation: move_left 15s infinite;
}

#dot_2{
    top: 10%;
    left: 10%;
    width: 20vw;
    height: 20vw;

}

#dot_3{
    top: 36%;
    left: 1vw;
    width: 15vw;
    height: 15vw;
}

#dot_4{
    top: 40%;
    right: -1vw;
    width: 47vw;
    height: 47vw;
    animation: move_left 15s infinite;
}

#dot_5{
    top: 58%;
    left: 10vw;
    width: 10vw;
    height: 10vw;
}

#dot_6{
    top: 74%;
    left: 15vw;
    width: 37vw;
    height: 37vw;

}

#dot_7{
    top: 89%;
    right: 15vw;
    width: 15vw;
    height: 15vw;
    animation: move_left 15s infinite;
}


@media screen and (max-width: 850px) {

    .dot{
        filter: blur(50px);
    }
    
    #dot_1{
        top: 20px;
        right: -1vw;
        width: 38vw;
        height: 38vw;
        filter: blur(40px);
    }
    
    #dot_2{
        top: 10%;
        left: 0%;
        width: 48vw;
        height: 48vw;
    
    }
    
    #dot_3{
        top: 38%;
        left: -10vw;
        width: 19vw;
        height: 19vw;
    }
    
    #dot_4{
        top: 50%;
        right: -10vw;
        width: 90vw;
        height: 90vw;
        filter: blur(60px);
    }
    
    #dot_5{
        top: 65%;
        left: 10vw;
        width: 10vw;
        height: 10vw;
    }
    
    #dot_6{
        top: 80%;
        left: 3vw;
        width: 18vw;
        height: 18vw;
    }
    
    #dot_7{
        top: 89%;
        right: 15vw;
        width: 36vw;
        height: 36vw;
    }
}

@media screen and (max-width: 550px) {
    .dot{
        filter: blur(30px);
    }
    #dot_1{
        top: 20px;
        right: -1vw;
        width: 38vw;
        height: 38vw;
        filter: blur(15px);
    }
    
    #dot_2{
        top: 11%;
        left: -10vw;
        width: 48vw;
        height: 48vw;
    
    }
    
    #dot_3{
        top: 30%;
        left: -120vw;
        width: 150vw;
        height: 150vw;
        animation: move_right 15s infinite;
    }
    
    #dot_4{
        top: 60%;
        right: -10vw;
        width: 90vw;
        height: 90vw;
    }
    
    #dot_5{
        top: 80%;
        right: 15vw;
        width: 53vw;
        height: 53vw;
    }
    
    #dot_6{
        top: 95%;
        left: 85vw;
        width: 36vw;
        height: 36vw;
    }
    
    #dot_7{
        display: none;
    }
}





@keyframes move_right{
    0%{
        transform: translate(0px, 0px);

    }

    50%{
        transform: translate(100px, 100px);

    }
    
    100%{
        transform: translate(0px, 0px);

    }
}

@keyframes move_left{
    0%{
        transform: translate(0px, 0px);

    }

    50%{
        transform: translate(100px, -100px);

    }
    
    100%{
        transform: translate(0px, 0px);

    }
}

