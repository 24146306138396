*{
    font-family: 'Poppins';
    font-style: normal;
    color: white;
}

.header_p{
    font-weight: 600;
    font-size: 1rem;
}

h1{
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
}

h2{
    font-weight: 600;
    font-size: 1.5rem;
}

p{
    font-weight: 400;
    font-size: 1rem;
}

.titre{
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 60px;  
}
