@import "../../styles/colors";

.Footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 64px 0px;
    gap: 32px;
    width: 100%;

    &_icones{
        display: flex;
        gap: 16px;
        & img {
            border-radius: 100px;
            transition: all 0.2s ease-in-out;
        }
        & img:hover{
            background-color: $main-color;
        }
    }
}


